import { getConfig } from './prepr';

export default async function getConfigProps({ req, locale, preview = false }) {
  const { host } = req.headers;
  const config = await getConfig({ host, locale, preview });

  return {
    props: {
      preview,
      config,
    },
  };
}
