import { LOCALES } from '@constants';
import LayoutBase from '@layouts/LayoutBase/LayoutBase';
import getConfigProps from '@lib/getConfigProps';
import PagepartBase from '@molecules/PagepartBase/PagepartBase';
import { isJobsDomain } from '@utils/getDomain';
import { StoreProvider } from '@utils/useStore';
import Link from 'next/link';
import { useEffect, useState } from 'react';

function Custom404Page() {
  const [config, setConfig] = useState(null);
  const [jobsDomain, setJobsDomain] = useState(false);

  useEffect(() => {
    setJobsDomain(isJobsDomain(window.location.host));
    const getConfig = async () => {
      const {
        props: { config: c },
      } = await getConfigProps({
        preview: false,
        locale: LOCALES[0],
        req: { headers: { host: window.location.host } },
      });

      setConfig(c);
    };

    getConfig();
  }, []);

  if (!config) return null;

  return (
    <StoreProvider store={{ config, host: window?.location?.host }}>
      <LayoutBase title="Pagina niet gevonden">
        <PagepartBase contained>
          <h1>Pagina niet gevonden</h1>
          <p>
            Niet gevonden wat je zocht? Misschien vind je hier wat je zoekt:
          </p>
          {jobsDomain ? (
            <ul>
              <li>
                <Link href="/">Home</Link>
              </li>
              <li>
                <Link href="/vacatures">Vacatures</Link>
              </li>
              <li>
                <Link href="/over-ons">Over ons</Link>
              </li>
              <li>
                <Link href="/over-ons/contact">Contact</Link>
              </li>
            </ul>
          ) : (
            <ul>
              <li>
                <Link href="/">Home</Link>
              </li>
              <li>
                <Link href="/actueel">Actueel</Link>
              </li>
              <li>
                <Link href="/over-ons">Over ons</Link>
              </li>
              <li>
                <Link href="/contact">Contact</Link>
              </li>
            </ul>
          )}
        </PagepartBase>
      </LayoutBase>
    </StoreProvider>
  );
}

Custom404Page.getLayout = function getLayout(page) {
  return page;
};

export default Custom404Page;
